<template>
  <web-layout :title="$t('notice.notice')" :titleTips="$t('notice.noticeTips')">
    <template>
      <el-row :gutter="20" class="location-header">
        <el-col :span="10">
          <el-input :placeholder="$t('notice.noticeQueryTips')" :title="query ? '' : $t('notice.noticeQueryTips')" v-model="query" @keyup.enter.native="searchEvent" clearable>
            <el-button icon="el-icon-search" @click="searchEvent" slot="append"></el-button>
          </el-input>
        </el-col>
        <el-col :span="14" style="text-align: right" v-if="!$store.getters.isDemo">
          <el-button type="primary" @click="createData" icon="el-icon-add-solid">{{ $t('common.publish') }}</el-button>
        </el-col>
      </el-row>
      <div ref="tableWrapper">
        <el-table ref="tableRef" :data="getalllist" style="width: 100%">
          <el-table-column prop="title" :label="$t('notice.noticeTitle')" min-width="180">
            <template slot-scope="{ row }">
              <span class="text-line-1" :title="row.title">{{ row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="outline" :label="$t('notice.noticeOutline')" min-width="300">
            <template slot-scope="{ row }">
              <span class="text-line-1" :title="row.outline">{{ row.outline }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createbyname" :label="$t('notice.publishName')" width="150"></el-table-column>
          <el-table-column :label="$t('notice.publishTime')" width="180">
            <template slot-scope="scope">
              {{ scope.row.timing == null || scope.row.timing == '' ? scope.row.createdate : scope.row.timing }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operation')" width="100">
            <template slot-scope="scope">
              <el-tooltip effect="light" :content="$t('common.viewDetail')" placement="top">
                <el-button @click="readClick(scope.row)" type="text" icon="el-icon-asset-detail"></el-button>
              </el-tooltip>
              <el-tooltip effect="light" :content="$t('common.delete')" placement="top" v-if="$store.getters.isOperate && !$store.getters.isDemo">
                <el-button @click="deleteClick(scope.row)" type="text" icon="el-icon-delete"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <div slot="empty">
            <el-empty :description="$t('notice.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
          </div>
        </el-table>
        <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getAllData"></pagination-box>
      </div>
    </template>

    <!-- 创建 -->
    <el-dialog :visible.sync="dialogVisible" destroy-on-close width="650px">
      <span slot="title" class="el-dialog__title">
        {{ $t('noticePublish.publishNotice') }}
        <el-tooltip :content="$t('noticePublish.noticeTips')" placement="bottom" effect="light">
          <i class="el-icon-question el-icon"></i>
        </el-tooltip>
      </span>

      <notice-publish-page v-if="dialogVisible" @createSuccess="createSuccess"></notice-publish-page>
    </el-dialog>

    <!-- 已读 -->
    <el-drawer :title="$t('noticeRead.noticeReadTitle')" :destroy-on-close="true" :visible.sync="drawerReadVisible">
      <notice-read-page v-if="drawerReadVisible" :viewData="viewData" @closeDialog="closeReadDrawer"></notice-read-page>
    </el-drawer>
  </web-layout>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
