import NoticePublishPage from '@/views/NoticePublish/index.vue'
import NoticeReadPage from '@/views/NoticeRead/index.vue'
export default {
  name: 'NoticePage',
  components: {
    NoticePublishPage,
    NoticeReadPage
  },
  data() {
    return {
      // 通知列表数据
      getalllist: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      // 搜索
      query: '',
      // 创建弹窗
      dialogVisible: false,
      // 查看数据
      viewData: null,
      // 查看信息弹窗
      drawerReadVisible: false

    }
  },
  mounted() {
    this.getAllData()

  },
  methods: {
    // 获取通知列表
    getAllData(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      this.$http
        .post('/notification/notifylist', {
          hrid: this.$store.getters.loginid,
          penum: this.$store.getters.penum,
          query: this.query,
          page: this.pagesObj.page,
          pagesize: this.pagesObj.pagesize,
        }, {
          loadTarget: this.$refs.tableWrapper
        }).then((response) => {
          if (response.data.success) {
            this.getalllist = response.data.content
            if (Array.isArray(this.getalllist) && !this.getalllist.length && this.pagesObj.page > 1) {
              this.getAllData(this.pagesObj.page - 1)
              return
            }
            this.pagesObj = {
              ...this.pagesObj,
              page: response.data.page,
              total: response.data.total
            }

          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
    },
    // 删除
    deleteClick(row) {
      this.$utils.blurFn()
      this.$confirm(this.$t('location.confirmDelete'), this.$t('common.alertPrompt'), {
        type: 'warning',
        center: true
      }).then(() => {

        this.$http.post('/notification/delnotify', {
          hrid: this.$store.getters.loginid,
          penum: this.$store.getters.penum,
          notifynum: row.notifynum,
        }).then((response) => {
          if (response.data.success) {
            this.$message({
              type: 'success',
              message: this.$t('notice.deleteSuccess')
            })
            this.getAllData(this.pagesObj.page)
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
      }).catch(() => {

      });
    },
    // 创建
    createData() {
      this.editData = null
      this.dialogVisible = true
    },
    // 创建成功
    createSuccess() {
      this.dialogVisible = false
      this.getAllData()
    },
    // 关闭弹窗
    closeDialog() {
      this.dialogVisible = false
    },
    // 查看已读
    readClick(item) {
      this.$utils.blurFn()
      this.viewData = item
      this.drawerReadVisible = true

    },
    closeReadDrawer() {
      this.drawerReadVisible = false
    },
    // 搜索
    searchEvent() {
      this.getAllData()
    },
  }
}